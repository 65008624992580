import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Hr, Button, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Hakkımızda | Habitat Harmony Interiors Hakkında
			</title>
			<meta name={"description"} content={"Habitat Harmony Interiors basit ama derin bir vizyondan doğdu: içinde yaşayan bireylerle derin bir yankı uyandıran iç mekanlar yaratmak."} />
			<meta property={"og:title"} content={"Hakkımızda | Habitat Harmony Interiors Hakkında"} />
			<meta property={"og:description"} content={"Habitat Harmony Interiors basit ama derin bir vizyondan doğdu: içinde yaşayan bireylerle derin bir yankı uyandıran iç mekanlar yaratmak."} />
			<meta property={"og:image"} content={"https://glory-troly.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://glory-troly.com/img/4635381.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://glory-troly.com/img/4635381.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://glory-troly.com/img/4635381.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://glory-troly.com/img/4635381.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://glory-troly.com/img/4635381.png"} />
			<meta name={"msapplication-TileImage"} content={"https://glory-troly.com/img/4635381.png"} />
		</Helmet>
		<Components.Header />
		<Section background="--color-primary" padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						display="flex"
						flex-direction="column"
						background="--color-light"
						color="--dark"
					>
						<Text
							as="h4"
							margin="12px 0"
							font="--base"
							color="--grey"
							letter-spacing="1px"
							text-transform="uppercase"
						>
							Habitat Uyumlu İç Mekanların Özü
						</Text>
						<Text as="h1" margin="12px 0" font="--headline1" md-font="--headline3">
							Hakkımızda
						</Text>
						<Text margin="12px 0" font="--lead" md-font="--headline3">
							Habitat Harmony Interiors basit ama derin bir vizyondan doğdu: içinde yaşayan bireylerle derin bir yankı uyandıran iç mekanlar yaratmak. Hikayemiz sadece oda tasarlamakla ilgili değil - yaşamları iyileştiren ve içinde yaşayanların benzersiz hikayelerini anlatan ortamları şekillendirmekle ilgili.
						</Text>
					</Box>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://glory-troly.com/img/a1.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://glory-troly.com/img/a2.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://glory-troly.com/img/3.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="150px 0 150px 0" lg-padding="80px 0 80px 0" quarkly-title="Content-14">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					display="flex"
					flex-direction="row"
					justify-content="center"
					lg-margin="0px 0px 50px 0px"
				>
					<Image
						src="https://glory-troly.com/img/a4.jpg"
						display="block"
						object-fit="cover"
						height="100%"
						lg-width="100%"
						lg-height="700px"
						sm-height="300px"
						margin="0px 5px 0px 0px"
						md-height="500px"
					/>
					<Image
						src="https://glory-troly.com/img/a5.jpg"
						display="block"
						object-fit="cover"
						height="100%"
						lg-width="100%"
						lg-height="700px"
						sm-height="300px"
						margin="0px 0px 0px 5px"
						md-height="500px"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					width="50%"
					padding="0px 0px 0px 80px"
					justify-content="center"
					lg-padding="0px 0px 0px 0"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="normal 400 56px/1.2 --fontFamily-serifGaramond"
						sm-font="normal 700 42px/1.2 --fontFamily-sans"
						color="--darkL1"
						lg-margin="0px 0px 35px 0px"
						text-align="center"
					>
						Tasarım Felsefemiz
					</Text>
					<Hr
						min-height="10px"
						margin="0px 0px 20px 0px"
						width="50px"
						display="flex"
						justify-content="center"
						border-width="2px 0 0 0"
						border-color="--color-darkL1"
					/>
					<Text margin="0px 0px 25px 0px" font="normal 300 18px/1.5 --fontFamily-sans" color="#474e53" text-align="center">
						Habitat Harmony'de iç tasarımın estetik güzellik ve pratik işlevsellik arasında hassas bir denge olduğuna inanıyoruz. Her projeye yeni bir tuval olarak yaklaşıyor, onu yaratıcılık, kişilik ve müşterilerimizin yaşam tarzlarıyla uyumlu bir uyum duygusuyla aşılamaya hevesli oluyoruz.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Habitat Harmony'yi Diğerlerinden Ayıran Özellikler
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Müşteri Odaklı Yaklaşım - İhtiyaçlarınız, tercihleriniz ve istekleriniz tasarım sürecimizin merkezinde yer alır. Sadece güzel değil, aynı zamanda sizin gerçek bir yansımanız olan mekanlar yaratıyoruz.
Detaylara Dikkat - Makro vizyondan mikro unsurlara kadar, mekanınızın her yönü titizlikle ele alınarak uyumlu ve ahenkli bir sonuç elde edilir.
				</Text>
				<LinkBox>
				<Button
					margin="20px 0px 0px 0px"
					href="/contacts"
					type="link"
					text-decoration-line="initial"
				>
					Bize Ulaşın
				</Button>
				</LinkBox>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://glory-troly.com/img/a6.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});